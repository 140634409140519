import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import { Transition } from 'react-transition-group';
import TableFilters from '../../components/pages/TransactionsPage/TableFilters';
import TransactionsTable from '../../components/pages/TransactionsPage/TransactionsTable';
import LoadMoreButton from '../../components/pages/TransactionsPage/LoadMoreButton';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader';
import {
  StyledTransactionsPageContainer,
  StyledTransactionsPageContent,
} from './StyledTransactionsPage';
import PageContainer from '../../components/PageContainer';
import TransactionDetailsPanel from '../../components/pages/TransactionsPage/TransactionDetailsPanel';
import TransactionDetails from '../../components/pages/TransactionsPage/TransactionDetails';
import NoSearchResults from '../../components/NoSearchResults';
import Empty from '../../components/Empty';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import InvoiceForward from '../../components/pages/InvoicesPage/InvoiceForward';
import AuthenticationWindow from '../../components/pages/CardsPage/AuthenticationWindow';
import InvoiceManager from '../../components/pages/TransactionsPage/InvoiceManager';
import {errorHandler} from '../../state/services/request';
import {
  bankingActions,
  companyActions,
  expenseActions,
  subscriptionActions,
  transactionActions
} from '../../state/actions';
import {ReactComponent as EmptySvgImage} from '../../static/images/pages/transactions/empty-transactions.svg';
import {invoiceTypeConstants, SCAActionsConstants} from '../../constants';
import {
  elementHelpers,
  objectHelpers,
  scaHelpers,
  subscriptionsHelpers,
  systemHelpers,
  transactionsHelpers
} from '../../utils/helpers';
import {firebaseEvents} from '../../snippets/firebase';
import {useCompanyBankingCreationDate, useHasAdminRights, useSearchParams} from '../../hooks';

const gObjProp = objectHelpers.getObjProp;

const {checkIsEnabledLoadMore} = subscriptionsHelpers;

const initialLoadedTransactionMaxCount = 25;
const duration = 300;
const numberOfMonthsWithoutAuth = 3;
const tableElementId = 'transactions-page-table-content';

const {details: detailsStyles, table: tableStyles} = transactionsHelpers.getAnimationStyles({duration});

const {TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION, TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION} = SCAActionsConstants;

const TransactionsPage = ({
  addInvoiceModalProps,
  getBatchExpenses,
  getCards,
  getEmployees,
  getSubscription,
  getTags,
  getTransactionsList,
  getUserTransactionsList,
  getTransactionsTotals,
  openAddInvoiceModal,
  isAdmin,
  employeeId,
  employees,
}) => {
  const [t] = useTranslation(['main', 'transactions']);
  const [loading, setLoading] = useState(true);
  const [groupedTransactions, setGroupedTransactions] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [totals, setTotals] = useState([]);
  const [loadMoreProps, setLoadMoreProps] = useState({cursor: undefined, loading: false, isEnabled: false, months: 1});
  const [filterParams, setFilterParams] = useState(null);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [activeOperationName, setActiveOperationName] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(undefined);
  const [isFixedDetailsWindow, setIsFixedDetailsWindow] = useState(false);
  const [detailsWindowFixedHeight, setDetailsWindowFixedHeight] = useState(0);

  const hasAdminRights = useHasAdminRights();

  const companyBankingCreationDate = useCompanyBankingCreationDate();

  const searchParams = useSearchParams().allParams;

  const tableNodeRef = useRef(null);
  const detailsNodeRef = useRef(null);

  const selectedSubscription = useMemo(() => {
    let subscription;
    const expense = gObjProp(selectedTransaction, 'expense');
    const subscriptionId = gObjProp(expense, 'subscription_id');
    if (isOpenDetails && subscriptionId) subscription = subscriptions.find(s => s.id === subscriptionId);
    return subscription;
  }, [subscriptions, isOpenDetails, selectedTransaction]);

  const defaultInvoiceType = useMemo(() => {
    let invoiceType = undefined;
    let expense = addInvoiceModalProps.transaction?.expense;
    if (expense) invoiceType = expense?.subscription_id ? invoiceTypeConstants.INVOICE : invoiceTypeConstants.RECEIPT;
    return invoiceType;
  }, [addInvoiceModalProps.transaction]);

  const {breadcrumbs, pageTitle} = useMemo(() => {
    let breadcrumbsTitle = t('transactions');
    const pageTitle = t(`pageTitles.${isAdmin ? 'transactionsList' : 'allTransactions'}`);
    if (!isAdmin) breadcrumbsTitle = `${t('all')} ${breadcrumbsTitle}`;
    return {
      breadcrumbs: [{title: breadcrumbsTitle}],
      pageTitle
    }
  }, [isAdmin, t]);

  const emptyT = (key) => t(`transactions:empty.${key}`);

  useEffect(() => {
    getCards();
    getEmployees();
    getTags();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (companyBankingCreationDate) {
      setLoadMoreProps({
        ...loadMoreProps,
        loading: false,
        isEnabled: checkIsEnabledLoadMore(companyBankingCreationDate, numberOfMonthsWithoutAuth),
      });
    }
  }, [companyBankingCreationDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isAdmin) {
      let offsetTop = elementHelpers.getElementOffsetTop(`#${tableElementId}`, 4) - 85;
      if (offsetTop !== detailsWindowFixedHeight) setDetailsWindowFixedHeight(offsetTop);
    }
  }, [isFixedDetailsWindow]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isAdmin) {
      transactionsHelpers.addFixedDetailsWindowScrollEventListener({
        detailsWindowFixedHeight, isOpenDetails, isFixedDetailsWindow, setIsFixedDetailsWindow
      });
    }
  }, [isOpenDetails, isFixedDetailsWindow, detailsWindowFixedHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const transactionsWithExpense = transactionsHelpers.getTransactionsWithExpense({expenses, employees, transactions});
    let groupedTransactions = transactionsHelpers.getGroupedTransactions(transactionsWithExpense, totals);
    setGroupedTransactions(groupedTransactions);
  }, [transactions, expenses, employees, totals]);

  const enableExport = useMemo(() => {
    return filterParams && filterParams.hasOwnProperty('card_id_in') ? filterParams.card_id_in.length === 0 : true;
  }, [filterParams]);

  const {isEmptySearchTransactions, isNotExistsTransactions} = useMemo(() => ({
    isEmptySearchTransactions: Boolean(filterParams?.search) && transactions.length === 0 && !loading,
    isNotExistsTransactions: !loadMoreProps.isEnabled && !loading && transactions.length === 0 && objectHelpers.isEmptyQuery(filterParams)
  }), [filterParams, loadMoreProps.isEnabled, loading, transactions]);

  const getLoadMoreTransactionsSuccessCallback = (response) => {
    getTransactionsSuccessCallback({
      response,
      previousExpenses: expenses,
      previousTransactions: transactions
    });

    if (!Boolean(response?.pagination)) {
      const subtractMonthsCount = loadMoreProps.months + 1;
      setLoadMoreProps({
        ...loadMoreProps,
        cursor: undefined,
        isEnabled: checkIsEnabledLoadMore(companyBankingCreationDate, subtractMonthsCount),
        loading: false,
        months: subtractMonthsCount,
      });
    }
  }

  const getTransactionsSuccessCallback = ({response, previousExpenses = [], previousTransactions = []} = {}) => {
    const transactions = response.hasOwnProperty('results') ? response.results : response || [];
    const expensesIds = transactions.map(t => t.expense_id).filter(value => value);
    const isExistsExpensesIds = expensesIds.length > 0;

    setTransactions([...previousTransactions, ...transactions]);
    setLoading(false);

    if (response.pagination) {
      const cursor = response?.pagination?.next_cursor;
      setLoadMoreProps({
        ...loadMoreProps,
        cursor,
        isEnabled: Boolean(cursor),
      });
    }

    if (isExistsExpensesIds) {
      getBatchExpenses(
        {expenses_ids: expensesIds},
        (expenses) => setExpenses([...previousExpenses, ...expenses])
      );
    } else if (!isExistsExpensesIds && expenses.length > 0) {
      setExpenses([]);
    }
  }

  const handleOnFilterChange = (query) => {
    setFilterParams(query);
    !loading && setLoading(true);
    getTransactionsListPromises({
      operationName: TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION,
      query,
      successCallback: (response) => getTransactionsSuccessCallback({response}),
      errorCallback: () => setLoading(false)
    });
  }

  const getTransactionsListPromises = ({
    query,
    successCallback,
    errorCallback,
    subtractMonthsCount,
    operationName
  }) => {
    const disableMonthSelection = !objectHelpers.isEmptyQuery(query) || (query.hasOwnProperty('has_invoice') && typeof query.has_invoice === 'boolean');
    const errorFunc = (response) => errorHandler(response, errorCallback);
    const transactionRequest = (subtractMonthsCount, successFunc, successTotalsFunc) => {
      const requestQuery = subscriptionsHelpers.getTransactionRequestQuery(query, subtractMonthsCount, disableMonthSelection);
      const getTransactionsFunc = isAdmin ? getTransactionsList : getUserTransactionsList;

      // load transactions list
      getTransactionsFunc({
        headers: scaHelpers.getAuthHeaders(operationName || activeOperationName),
        userId: employeeId,
        query: requestQuery,
        successCallback: successFunc,
        errorCallback: (response) => {
          scaHelpers.SCAResponseCallback({
            response,
            scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
            errorCallback: errorFunc
          });
        }
      });

      // load totals only if request query has month and year params
      if (isAdmin && requestQuery.hasOwnProperty('month') && requestQuery.hasOwnProperty('year')) {
        getTransactionsTotals({
          headers: scaHelpers.getAuthHeaders(operationName || activeOperationName),
          query: requestQuery,
          successCallback: successTotalsFunc
        });
      } else {
        setTotals([]);
      }
    }

    let storedTransactions = [];
    let storedTotals = [];

    activeOperationName !== operationName && setActiveOperationName(operationName);

    if (subtractMonthsCount || disableMonthSelection) {
      transactionRequest(
        subtractMonthsCount,
        (response) => successCallback && successCallback(response),
        (response) => setTotals([...totals, response])
      );
    } else {
      const loadTransactions = (index, maxLength) => {
        const successFunc = (data) => {
          storedTransactions = [...storedTransactions, ...data];
          let nextIndex = index + 1;
          if (loadMoreProps.months !== nextIndex) {
            setLoadMoreProps({
              ...loadMoreProps,
              months: nextIndex,
              isEnabled: true
            });
          }
          if (nextIndex < maxLength && storedTransactions.length <= initialLoadedTransactionMaxCount) {
            setTransactions(storedTransactions);
            loadTransactions(nextIndex, maxLength);
          } else {
            successCallback && successCallback(storedTransactions);
          }
        }
        const successTotalsFunc = (data) => {
          storedTotals = [...storedTotals, data];
          setTotals(storedTotals);
        }
        transactionRequest(index, successFunc, successTotalsFunc);
      }

      loadTransactions(0, numberOfMonthsWithoutAuth);
    }
  }

  const handleLoadMore = () => {
    if (loadMoreProps.isEnabled) {
      const {cursor, months} = loadMoreProps;
      const subtractMonthsCount = months + 1;
      let updateLoadMoreProps = {
        ...loadMoreProps,
        loading: true
      }
      const enabledPagination = Boolean(cursor);
      if (!enabledPagination) {
        updateLoadMoreProps = {
          ...updateLoadMoreProps,
          cursor: undefined,
          months: subtractMonthsCount,
          isEnabled: checkIsEnabledLoadMore(companyBankingCreationDate, subtractMonthsCount),
        }
      }
      setLoadMoreProps({...loadMoreProps, loading: true});

      getTransactionsListPromises({
        operationName: TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION,
        query: {...filterParams, cursor},
        successCallback: getLoadMoreTransactionsSuccessCallback,
        errorCallback: () => {
          setLoading(false);
          setLoadMoreProps({...updateLoadMoreProps, loading: false});
        },
        subtractMonthsCount: months,
      });
    }
  }

  const onExpenseUpdate = (updatedExpense, actionType) => {
    if (updatedExpense) {
      let updatedExpenses = transactionsHelpers.getUpdatedBatchExpensesList(expenses, updatedExpense, actionType);
      setExpenses(updatedExpenses);
      if (selectedTransaction) {
        setSelectedTransaction({
          ...selectedTransaction,
          expense: {
            ...selectedTransaction.expense,
            ...updatedExpense
          }
        })
      }
    }
  }

  const successChangeInvoiceHandle = (response, successCallback) => {
    successCallback && successCallback(response);
    systemHelpers.logEvent(firebaseEvents.TRANSACTIONS_UPDATE_TRANSACTION_INVOICE);
  }

  const handleCloseDetails = () => {
    setIsOpenDetails(false);
    selectedRowIndex && setSelectedRowIndex(undefined);
  }

  const handleOpenDetails = (transactionId, index) => {
    let subscriptionId = null;
    let transaction = groupedTransactions.find(d => d.id === transactionId);
    if (transaction) {
      const expense = expenses.find(e => e.id === transaction.expense_id);
      if (expense) {
        subscriptionId = expense.subscription_id;
        transaction = {...transaction, expense: {...transaction.expense, ...expense}}
      }
    }
    if (subscriptionId) {
      const subscription = subscriptions.find(s => s.id === subscriptionId);
      if (subscription === undefined) {
        getSubscription(
          subscriptionId,
          (subscription) => setSubscriptions([...subscriptions, subscription])
        );
      }
    }
    setSelectedTransaction(transaction);
    setIsOpenDetails(true);
    selectedRowIndex !== index && setSelectedRowIndex(index);
  }

  const handleCloseAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    loading && setLoading(false);
    if (activeOperationName === TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION) {
      setLoadMoreProps({...loadMoreProps, loading: false});
    }
  }

  const handleOnSuccessAuth = () => {
    handleCloseAuthModal();
    if (activeOperationName === TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION) {
      setLoading(true);
      handleOnFilterChange(filterParams);
    } else {
      handleLoadMore();
    }
  }

  return (
    <PageContainer>
      <PageDocumentDetails title={pageTitle} />
      <BalancePageHeader breadcrumbs={breadcrumbs} />
      {!isAdmin && <InvoiceForward />}
      <StyledTransactionsPageContainer className={isOpenDetails && 'open-details'}>
        {isNotExistsTransactions ? (
          <Empty
            description={emptyT('description')}
            image={<EmptySvgImage />}
            title={emptyT('title')}
          />
        ) : (
          <>
            <Transition nodeRef={tableNodeRef} in={isOpenDetails} timeout={duration}>
              {state => (
                <StyledTransactionsPageContent
                  className={!isAdmin && 'with-invoice-forward'}
                  id={tableElementId}
                  style={{
                    ...tableStyles.default,
                    ...tableStyles[state]
                  }}
                >
                  <TableFilters
                    enableExport={enableExport}
                    onFilter={handleOnFilterChange}
                    searchParams={searchParams}
                  />
                  {isEmptySearchTransactions ? (
                    <NoSearchResults
                      spinning={loading}
                      value={filterParams?.search || ''}
                    />
                  ) : (
                    <>
                      <TransactionsTable
                        data={groupedTransactions}
                        emptyMessage={loading ? ' ' : undefined}
                        isCollapsed={isOpenDetails}
                        loading={loading}
                        onRowClick={handleOpenDetails}
                        onInvoiceEdit={openAddInvoiceModal}
                        selectedRowIndex={selectedRowIndex}
                      />

                      <LoadMoreButton
                        isEnabled={loadMoreProps.isEnabled}
                        loading={loading || loadMoreProps.loading}
                        modalTitle={t('showMoreTransactions')}
                        onClick={handleLoadMore}
                        size='large'
                      >
                        {t('showMore')}
                      </LoadMoreButton>
                    </>
                  )}
                </StyledTransactionsPageContent>
              )}
            </Transition>
            <Transition nodeRef={detailsNodeRef} in={isOpenDetails} timeout={duration}>
              {state => (
                <TransactionDetailsPanel
                  className='transaction-details-panel'
                  contentClassName='w-100 transaction-details-panel-content'
                  onBack={handleCloseDetails}
                  fixed={isAdmin ? true : isFixedDetailsWindow}
                  hiddenOverflow={!isOpenDetails}
                  style={{
                    ...detailsStyles.default,
                    ...detailsStyles[state]
                  }}
                >
                  <TransactionDetails
                    edit={hasAdminRights}
                    onExpenseUpdate={onExpenseUpdate}
                    onInvoiceEdit={openAddInvoiceModal}
                    subscription={selectedSubscription}
                    transaction={selectedTransaction}
                  />
                </TransactionDetailsPanel>
              )}
            </Transition>
          </>
        )}

        <InvoiceManager
          isOpenDetails={isOpenDetails}
          onExpenseUpdate={onExpenseUpdate}
          successUpdateHandler={successChangeInvoiceHandle}
          selectedTransaction={selectedTransaction}
          modalProps={{defaultInvoiceType}}
        />

        <AuthenticationWindow
          {...authWindowProps}
          authModalProps={{title: `${t('transactions')} ${t('loading')}`}}
          handleCancel={handleCloseAuthModal}
          onSuccess={handleOnSuccessAuth}
          operationName={activeOperationName}
        />

      </StyledTransactionsPageContainer>
    </PageContainer>
  );
}

const mapStateToProps = state => {
  const {employees} = state.company;
  const {addInvoiceModalProps} = state.transaction;
  const {user} = state;
  const isAdmin = user.isAdmin;
  const employeeId = user?.employee?.id;

  return {
    addInvoiceModalProps,
    isAdmin,
    employeeId,
    employees
  }
}

const mapDispatchToProps = {
  getBatchExpenses: expenseActions.getBatchExpenses,
  getCards: bankingActions.getCardsList,
  getTransactionsList: transactionActions.getTransactionsList,
  getUserTransactionsList: transactionActions.getUserTransactionsList,
  getTransactionsTotals: transactionActions.getTransactionsTotals,
  getTags: companyActions.getTags,
  getEmployees: companyActions.getEmployees,
  getSubscription: subscriptionActions.getSubscription,
  openAddInvoiceModal: transactionActions.openAddInvoiceModal
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
