import styled from 'styled-components';
import {Button} from 'antd';
import Card from '../../../Card';
import InvoiceAttachmentPreview from '../InvoiceAttachmentPreview';

const StyledInvoiceDetailsCard = styled(Card)`
  margin-top: 16px;

  .ant-card-body {
    position: relative;
    text-align: center;

    button:not(.ant-btn-icon-only) {
      color: ${props => props.theme.colors.primary_6};

      &:hover {
        color: ${props => props.theme.colors.primary_6};
      }
    }
  }
`;

const StyledInvoiceDetailsFilePreview = styled.div`
  position: relative;
`;

const StyledInvoiceDetailsAttachmentPreview = styled(InvoiceAttachmentPreview)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledInvoiceDetailsDownloadButton = styled(Button)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary_6};
  padding: 0;
  
  svg {
    margin-right: 4px;
    path {
      fill: ${props => props.theme.colors.primary_6};
      transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }
  
  &.ant-btn-text:not(:disabled):not(.ant-btn-disabled) {
    &:hover {
      background-color: unset;
      color: ${props => props.theme.colors.primary_7};
      
      svg {
        path {
          fill: ${props => props.theme.colors.primary_7};
        }
      }
    }
    
    &:active,
    &:focus {
      background-color: unset;
      color: ${props => props.theme.colors.primary_6};
      
      svg {
        path {
          fill: ${props => props.theme.colors.primary_6};
        }
      }
    }
  }
`;

export {
  StyledInvoiceDetailsFilePreview,
  StyledInvoiceDetailsCard,
  StyledInvoiceDetailsAttachmentPreview,
  StyledInvoiceDetailsDownloadButton
};
