import React from 'react';
import {
  expenseInvoicesStatusConstants,
  cardPaymentStatusesConstants,
  invoiceTypeConstants,
  expenseTypesConstants,
  transactionTypesConstants
} from '../../constants';
import {
  FileInvoiceIcon,
  ReceiptDeclinedIcon,
  ReceiptIcon,
  RepeatIcon,
  TransactionAddIcon,
  TransactionIcon
} from '../../icons';
import UserDetails from '../../components/UserDetails';
import CreditCardDetails from '../../components/pages/CardsPage/CreditCardDetails';
import {amountHelpers} from './amount.helpers';
import {colorHelpers} from './color.helpers';
import {objectHelpers} from './object.helpers';
import {textHelpers} from './text.helpers';

const {CARD_TRANSACTION} = transactionTypesConstants;

const {AUTHORIZATION_ACCEPTED, CLEARED, DECLINED, REFUND, REVERSED, SETTLED} = cardPaymentStatusesConstants;

const gMV = amountHelpers.getAmountWithCurrencyCode;

const transactionDetailsMaxWidth = `${472 + 24}px`;
const tableCollapseWidth = `calc(100% - ${transactionDetailsMaxWidth})`;

const cardPaymentStatuses = {
  [AUTHORIZATION_ACCEPTED]: {
    key: 'cardPaymentStatuses.accepted',
    color: '#52C41A'
  },
  [CLEARED]: {
    key: 'cardPaymentStatuses.cleared',
    color: '#1890FF'
  },
  [DECLINED]: {
    key: 'cardPaymentStatuses.declined',
    color: '#FF7875'
  },
  [REFUND]: {
    key: 'cardPaymentStatuses.refund',
    color: '#722ED1'
  },
  [REVERSED]: {
    key: 'cardPaymentStatuses.reversed',
    color: '#FFCC00'
  },
  [SETTLED]: {
    key: 'cardPaymentStatuses.settled',
    color: '#52C41A'
  },
};

export const transactionsHelpers = {
  additionalTransactionsGrouping: (transactions, summarizeKeyName) => transactions.map((t, key) => (
    (key !== 0 && key !== transactions.length - 1 && transactions[key + 1][summarizeKeyName])
      ? {...t, isLatest: true} : t)
  ),
  getAnimationStyles: ({
    defaultTableWidth = '100%',
    duration
  } = {}) => {
    const defaultStyle = {
      transition: `width ${duration}ms ease-in-out`
    };
    return {
      details: {
        default: defaultStyle,
        entering: {width: transactionDetailsMaxWidth},
        entered: {width: transactionDetailsMaxWidth},
        exiting: {width: 0},
        exited: {width: 0}
      },
      table: {
        default: defaultStyle,
        entering: {width: tableCollapseWidth},
        entered: {width: tableCollapseWidth},
        exiting: {width: defaultTableWidth},
        exited: {width: defaultTableWidth}
      }
    }
  },
  isReturnedTransaction: (transaction) => transaction && [DECLINED, REVERSED].includes(transaction.payment_status),
  getTransactionAmount: ({transaction, variant, groupingKey = 'isMonthGrouping'}) => {
    if (transaction && typeof transaction === 'object') {
      let className = '';
      let {amount} = transaction;
      if (transaction[groupingKey]) {
        className = 'total-amount';
        amount = transaction[variant] || 0;
        if (amount > 0 && variant === 'outcome') className = `${className} danger-text`;
        if (variant === 'outcome' && Math.sign(amount) > 0) amount = amount * -1;
        return <span className={className}>{gMV(amount)}</span>;
      } else {
        const isIncoming = transaction.is_incoming || false;
        if ((isIncoming && variant !== 'income') || (!isIncoming && variant !== 'outcome')) return '';
        const price = gMV(amount);
        const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(transaction);
        if (!isIncoming && !isReturnedTransaction) className = `${className} danger-text`;
        if (isReturnedTransaction) className = `${className} returned-price`;
        return amount ? isIncoming ? price : <span className={className}>-{gMV(Math.abs(amount))}</span> : null;
      }
    } else {
      return null
    }
  },
  getTransactionAuthor: (transaction) => {
    if (transaction && typeof transaction === 'object' && transaction.transaction_type === CARD_TRANSACTION) {
      return (
        <UserDetails
          email={false}
          user={transaction.user}
        />
      );
    } else {
      return null;
    }
  },
  getTransactionSource: (transaction) => {
    let value = '';
    if (typeof transaction === 'object') {
      const description = objectHelpers.getObjProp(transaction, 'description');
      const source = objectHelpers.getObjProp(transaction, 'source');
      if (source) {
        value = source;
      } else if (description && description !== '') {
        value = textHelpers.cutString(description.split(' ')[0], 20)
      }
    }
    return value;
  },
  getTransactionStatus: (status) => {
    const statuses = {...cardPaymentStatuses};
    Object.keys(statuses).forEach(key => {
      statuses[key].bgColor = colorHelpers.hex2rgba(statuses[key].color, 0.15);
    })
    return objectHelpers.getStatus(status, statuses);
  },
  getTransactionType: (transaction) => {
    if (transaction && typeof transaction === 'object') {
      return (
        <span className='transaction-type'>
          {transaction.transaction_type === CARD_TRANSACTION ? (
            <>
              <CreditCardDetails
                cardNumber={transaction.masked_pan}
                cardNumberClassName='card-number'
              />
            </>
          ) : <RepeatIcon />}
        </span>
      )
    } else {
      return null;
    }
  },
  getTransactionAttachmentIcon: ({ transaction, ...rest }) => {
    if (!transaction?.expense) return null;

    const { onClick } = rest;
    const { expense_type, expense_invoice_status } = transaction.expense;

    const iconTypes = {
      [expenseInvoicesStatusConstants.CONFIRMED]: <TransactionIcon />,
      [expenseInvoicesStatusConstants.MATCHED]: <TransactionIcon />
    };

    const icon =
      expense_type === expenseTypesConstants.NO_RECEIPT
        ? <TransactionAddIcon />
        : iconTypes[expense_invoice_status] || <TransactionAddIcon />;

    return (
      <span
        className='transaction-icon'
        {...rest}
        onClick={(e) => onClick?.({ e, transaction })}
      >
        {icon}
      </span>
    );
  },
  getWireDetails: (details) => {
    const gObjProp = (propName) => objectHelpers.getObjProp(details, propName);
    const getFormattedValue = (value) => value ? value.match(/.{1,4}/g).join(' ') : '';

    return {
      bic: getFormattedValue(gObjProp('bic')),
      iban: getFormattedValue(gObjProp('iban'))
    };
  },
  getInvoiceEmail: (settings) => {
    let email = null;
    const gObjProp = (propName) => objectHelpers.getObjProp(settings, propName);

    if (settings) email = gObjProp('invoice_email_alias') || gObjProp('invoice_email') || null;
    return email;
  },
  addFixedDetailsWindowScrollEventListener: ({detailsWindowFixedHeight, isOpenDetails, isFixedDetailsWindow, setIsFixedDetailsWindow}) => {
    const setScroll = (e) => {
      let scrolled = false;
      if (!isOpenDetails) return;
      if (e.target.scrollTop >= detailsWindowFixedHeight) scrolled = true;
      if (isFixedDetailsWindow !== scrolled) setIsFixedDetailsWindow(scrolled);
    };
    document.querySelector('#app-page-container')?.addEventListener('scroll', setScroll);
    return () => {
      document.querySelector('#app-page-container')?.removeEventListener('scroll', setScroll);
    };
  },
  getGroupedTransactions: (transactions, totals = []) => {
    const daysList = {};
    const datePropName = 'created_date';
    const summarizeKeyName = 'isMonthGrouping';
    let groupedTransactions = [];
    let lastAdded;

    // define transactions months
    transactions.forEach(transaction => {
      const date = transaction[datePropName].slice(0, 7);
      const {is_incoming: isIncoming} = transaction;
      const amount = Number(transaction.amount) || 0;
      const income = isIncoming ? amount : 0;
      const outcome = isIncoming ? 0 : amount;
      const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(transaction);
      if (!isReturnedTransaction) {
        if (daysList.hasOwnProperty(date)) {
          daysList[date] = {
            income: daysList[date].income + income,
            outcome: daysList[date].outcome + outcome
          }
        } else {
          daysList[date] = {
            income,
            outcome
          }
        }
      }
    });

    // add grouping rows to transactions list
    transactions.forEach(t => {
      const date = t[datePropName].slice(0, 7);
      if (lastAdded !== date && daysList.hasOwnProperty(date)) {
        let groupedTransaction = {
          id: date,
          [summarizeKeyName]: true,
          [datePropName]: date,
          ...daysList[date]
        }
        const totalByDate = totals.find(t => `${t.year}-${t.month}` === date);
        if (totalByDate) {
          groupedTransaction = {
            ...groupedTransaction,
            income: totalByDate?.total_in,
            outcome: totalByDate?.total_out
          }
        }
        lastAdded = date;
        groupedTransactions.push(groupedTransaction);
      }
      groupedTransactions.push(t);
    });
    groupedTransactions = transactionsHelpers.additionalTransactionsGrouping(groupedTransactions, summarizeKeyName);
    return groupedTransactions;
  },
  getInvoiceTypeOptions: ({t, enabledEmpty = false}) => {
    let options = [
      {
        icon: <FileInvoiceIcon />,
        label: t(`invoiceTypes.invoice`),
        value: invoiceTypeConstants.INVOICE
      },
      {
        icon: <ReceiptIcon />,
        label: t(`invoiceTypes.receipt`),
        value: invoiceTypeConstants.RECEIPT
      }
    ];
    if (enabledEmpty) {
      options = [
        ...options,
        {
          icon: <ReceiptDeclinedIcon />,
          label: t(`invoiceTypes.noReceipt`),
          value: invoiceTypeConstants.NO_RECEIPT
        }
      ];
    }
    return options;
  },
  getExpenseTypeOptions: ({t}) => {
    return [
      {
        label: t(`expenseTypes.invoice`),
        value: expenseTypesConstants.INVOICE
      },
      {
        label: t(`expenseTypes.receipt`),
        value: expenseTypesConstants.RECEIPT
      },
      {
        label: t(`expenseTypes.noReceipt`),
        value: expenseTypesConstants.NO_RECEIPT
      }
    ];
  },
  isMonthlyGroupedRow: (row) => row.isMonthGrouping,
  getTransactionsWithExpense: ({employees, expenses, transactions}) => {
    return transactions.map(t => {
      const {expense_id: expenseId, user_id: userId} = t;
      const user = employees.find(e => e.employee_id === userId);
      const expense = expenses.find(e => e.id === expenseId);
      return {
        ...t,
        expense,
        user
      }
    });
  },
  getRowClassName: ({
    groupedPropName,
    index,
    record,
    selectedRowIndex
  }) => {
    let className = '';
    if (record[groupedPropName]) className += ' grouped-row';
    if (index === selectedRowIndex) className += ' selected-row';
    return className;
  },
  getUpdatedBatchExpensesList: (expenses, updatedExpense, actionType) => {
    return expenses.map(expense => {
      if (expense.id !== updatedExpense.id) {
        return expense;
      } else {
        let expenseObj = {
          ...expense,
          ...updatedExpense
        }
        if (actionType !== 'attachment') {
          expenseObj = {
            ...expenseObj,
            budget: updatedExpense.budget,
            fund: updatedExpense.fund,
            description: updatedExpense.description,
            tags: updatedExpense.tags.length > 0,
            tags_list: updatedExpense.tags_list
          }
        }
        return expenseObj;
      }
    });
  },
  getInvoiceModalMode: ({addInvoiceModalProps, isOpenDetails, selectedTransaction}) => {
    return isOpenDetails && objectHelpers.arraysIsEqual(selectedTransaction, addInvoiceModalProps.transaction) ? 'edit' : 'add';
  }
}
