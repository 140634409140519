import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Checkbox, Select, Space} from 'antd';
import {
  StyledTableFilters,
  StyledTableFiltersCardSelect,
  StyledTableFiltersDivider,
  StyledTableFiltersSearch,
  StyledTableFiltersSegmented
} from './StyledTableFilters';
import ExportModal from '../ExportModal';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow/AuthenticationWindow';
import {useIsMount} from '../../../../hooks';
import {expenseActions, transactionActions} from '../../../../state/actions';
import {formHelpers, scaHelpers, systemHelpers} from '../../../../utils/helpers';
import {cardPaymentStatusesConstants, SCAActionsConstants} from '../../../../constants';
import {firebaseEvents} from '../../../../snippets/firebase';

const {logEvent} = systemHelpers;

const SCA_OPERATION_NAME = SCAActionsConstants.TRANSACTIONS_DOWNLOAD_PDF_AND_CSV_ACTION;

const UNMATCHED = 'unmatched';

const getPaymentStatusValue = (value) => {
  let paymentStatus = [];
  let hasInvoice = undefined;
  if (value === UNMATCHED) {
    hasInvoice = false;
  } else {
    paymentStatus = value === 'all' ? [] : [value];
  }
  return {
    payment_status: paymentStatus,
    has_invoice: hasInvoice
  }
}

const selectProps = {
  allowClear: true,
  maxTagCount: 1,
  mode: 'multiple',
  size: 'large',
  showSearch: true
}

const TableFilters = ({
  enableExport,
  onFilter,
  searchParams,

  cards,
  employees,
  employeeEmail,
  isAdmin,
  isKnownEmployee,

  downloadTransactions,
  downloadZipInvoices,
}) => {
  const [t] = useTranslation(['main', 'cards']);
  const [exportModalProps, setExportModalProps] = useState({loading: false, open: false});
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [exportDetails, setExportDetails] = useState({range: undefined, format: null});

  const [filter, setFilter] = useState({
    cards: [],
    paymentStatus: 'all',
    search: '',
    types: [],
    users: []
  });
  const isMounted = !useIsMount();

  useEffect(() => {
    if (isKnownEmployee) {
      if (searchParams && searchParams.hasOwnProperty('card')) {
        const {card: selectedCard} = searchParams;
        if (!filter.types.includes('card') && !filter.cards.includes(selectedCard)) {
          setFilter({
            ...filter,
            cards: [...filter.cards, selectedCard],
            types: [...filter.types, 'card']
          });
        }
      } else {
        handleFilter(filter);
      }
    }
  }, [isKnownEmployee]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => isMounted && handleFilter(filter), [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardOptions = useMemo(() => formHelpers.getCardsOptions(cards), [cards]);

  const employeeOptions = useMemo(() =>
    formHelpers.getEmployeeOptions({employees, employeeEmail, t, valueKey: 'employee_id'}), [employees, employeeEmail, t]);

  const paymentStatusOptions = [
    {label: t('all'), value: 'all'},
    {label: t('unmatched'), value: UNMATCHED},
    {label: t('cardPaymentStatuses.declined'), value: cardPaymentStatusesConstants.DECLINED}
  ];

  const handleFilter = (filterData) => {
    const {cards, users, search, paymentStatus, types} = filterData;
    let isCardTransaction = undefined;
    if (types.length === 1) isCardTransaction = types[0] === 'card';

    onFilter && onFilter({
      card_id_in: cards,
      is_card_transaction: isCardTransaction,
      search,
      user_id_in: users,
      ...getPaymentStatusValue(paymentStatus)
    });
  }

  const handleOnSelectCard = (value, options) => {
    setFilter({
      ...filter,
      cards: options.map(o => o.id)
    });
  }

  const handleOnFilter = (key, value) => setFilter({...filter, [key]: value});

  const handleChangePaymentStatus = (value) => setFilter({...filter, paymentStatus: value});

  const handleExportTransactions = (data) => {
    const {format, date, date_range: dateRange} = data;
    let query;
    const timeFormat = 'YYYY-MM-DD';
    const isDefinedDateRange = dateRange && dateRange.length === 2;

    const errorCallback = () => setExportModalProps({...exportModalProps, open: true, loading: false});
    setExportModalProps({...exportModalProps, loading: true});
    if (format === 'zip') {
      if (!isDefinedDateRange) return;
      query = {
        date_gte: dateRange[0].format(timeFormat),
        date_lte: dateRange[1].format(timeFormat),
      };
      downloadZipInvoices(
        query,
        (data) => {
          window.open(data.url, 'download');
          handleCancelExportModal();
          logEvent(firebaseEvents.TRANSACTIONS_DOWNLOAD_ZIP_INVOICES);
        },
        errorCallback
      )
    } else {
      let startDate = date, endDate = date;
      if (format === 'csv' && isDefinedDateRange) {
        startDate = dateRange[0];
        endDate = dateRange[1];
      }
      query = {
        date_gte: startDate.format(timeFormat),
        date_lte: endDate.format(timeFormat),
        file_format: format
      };
      const downloadLogEvents = {
        'csv': firebaseEvents.TRANSACTIONS_EXPORT_CSV,
        'pdf': firebaseEvents.TRANSACTIONS_EXPORT_PDF
      }
      const downloadLogEvent = downloadLogEvents[format];
      downloadTransactions({
        headers: scaHelpers.getAuthHeaders(SCA_OPERATION_NAME),
        query,
        successCallback: (data) => {
          window.open(data.url, 'download');
          handleCancelExportModal();
        },
        errorCallback: (response) => {
          scaHelpers.SCAResponseCallback({
            response,
            scaCallback: (scaProps) => {
              setAuthWindowProps({...authWindowProps, ...scaProps});
              setExportDetails(data);
              downloadLogEvent && logEvent(downloadLogEvent);
            },
            errorCallback
          });
        }
      });
    }
  }

  const handleOnExportClick = () => setExportModalProps({...exportModalProps, open: true});

  const handleCancelExportModal = () => setExportModalProps({...exportModalProps, open: false, loading: false});

  const handleCloseAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    setExportModalProps({...exportModalProps, loading: false});
  }

  const handleOnSuccessAuth = () => {
    handleCloseAuthModal();
    handleExportTransactions(exportDetails);
  }

  return (
    <StyledTableFilters align='center' wrap>
      <Space align='center' size='small' wrap>
        <StyledTableFiltersSearch
          allowClear
          onSearch={(value) => handleOnFilter('search', value)}
          placeholder={t('search')}
          size='large'
        />
        <StyledTableFiltersCardSelect
          {...selectProps}
          menuItemSelectedIcon={false}
          onChange={handleOnSelectCard}
          placeholder={t('cards:tableFilters.placeholder.cards')}
        >
          {cardOptions.map(({label, value, id}) => (
            <Select.Option key={value} value={value} id={id}>
              <Space align='center' size='small'>
                <Checkbox
                  checked={filter.cards.includes(id)}
                  readable={false}
                />
                {label}
              </Space>
            </Select.Option>
          ))}
        </StyledTableFiltersCardSelect>
        {isAdmin && (
          <StyledTableFiltersCardSelect
            {...selectProps}
            dropdownStyle={{minWidth: 235}}
            onChange={(value) => handleOnFilter('users', value)}
            optionLabelProp='optionLabel'
            options={employeeOptions}
            placeholder={t('cards:tableFilters.placeholder.users')}
            value={filter.users}
          />
        )}
        <StyledTableFiltersSegmented
          onChange={handleChangePaymentStatus}
          options={paymentStatusOptions}
          value={filter.paymentStatus}
        />
      </Space>
      {(isAdmin && enableExport) && (
        <>
          <Space size={0}>
            <StyledTableFiltersDivider type='vertical' />
            <Button
              onClick={handleOnExportClick}
              size='large'
              type='primary'
            >
              {t('export')}
            </Button>
          </Space>

          <ExportModal
            {...exportModalProps}
            handleCancel={handleCancelExportModal}
            handleOk={handleExportTransactions}
            title={`${t('export')} ${t('transactions')}`}
          />

          <AuthenticationWindow
            {...authWindowProps}
            handleCancel={handleCloseAuthModal}
            onSuccess={handleOnSuccessAuth}
            operationName={SCA_OPERATION_NAME}
          />
        </>
      )}

    </StyledTableFilters>
  );
}

TableFilters.propTypes = {
  enableExport: PropTypes.bool,
  onFilter: PropTypes.func,
  searchParams: PropTypes.object,
}

TableFilters.defaultProps = {
  enableExport: false
}

const mapStateToProps = state => {
  const {employee, isKnownEmployee, isAdmin} = state.user;
  const {cards} = state.banking;
  const {employees} = state.company;
  return {
    cards,
    employees,
    employeeEmail: employee.email,
    isKnownEmployee,
    isAdmin
  }
}

const mapDispatchToProps = {
  downloadTransactions: transactionActions.downloadTransactions,
  downloadZipInvoices: expenseActions.downloadZipInvoices
}

export default connect(mapStateToProps, mapDispatchToProps)(TableFilters);
