import styled from 'styled-components';

const StyledInvoiceTypeRadioGroup = styled.div`
  margin-bottom: 16px;
  
  label.ant-radio-wrapper-checked {
    font-weight: 400;
  }
`;

const StyledInvoiceTypeRadioGroupLabel = styled.label`
  display: inline-block;
  margin-bottom: 8px;
`;


export {
  StyledInvoiceTypeRadioGroup,
  StyledInvoiceTypeRadioGroupLabel
}
