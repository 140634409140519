import React from 'react';

const ReceiptDeclinedIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.67909 24.9561C6.15378 24.4487 5.73478 23.8418 5.44653 23.1708C5.15828 22.4998 5.00655 21.7781 5.00021 21.0478C4.99386 20.3175 5.13302 19.5933 5.40957 18.9173C5.68611 18.2414 6.0945 17.6273 6.61091 17.1109C7.12732 16.5945 7.74141 16.1861 8.41734 15.9096C9.09327 15.633 9.81751 15.4939 10.5478 15.5002C11.2781 15.5066 11.9998 15.6583 12.6708 15.9465C13.3418 16.2348 13.9487 16.6538 14.4561 17.1791C15.458 18.2164 16.0123 19.6057 15.9998 21.0478C15.9873 22.4899 15.4088 23.8693 14.3891 24.8891C13.3693 25.9088 11.9899 26.4873 10.5478 26.4998C9.10571 26.5123 7.7164 25.958 6.67909 24.9561ZM11.3376 21.0676L12.8941 19.5111L12.1186 18.7356L10.5676 20.2921L9.01109 18.7356L8.23559 19.5111L9.79209 21.0676L8.23559 22.6241L9.01109 23.3996L10.5676 21.8431L12.1241 23.3996L12.8996 22.6241L11.3431 21.0676H11.3376Z"
      fill="#B5B8BF"/>
    <path
      d="M8 7.5C8 6.83696 8.25022 6.20107 8.69562 5.73223C9.14102 5.26339 9.74511 5 10.375 5H20.4028C21.0327 5 21.6368 5.26339 22.0822 5.73223C22.5276 6.20107 22.7778 6.83696 22.7778 7.5V17.2222H27V21.3889C27 21.8631 26.9113 22.3327 26.7389 22.7708C26.5665 23.2089 26.3138 23.607 25.9952 23.9423C25.6767 24.2777 25.2985 24.5436 24.8823 24.7251C24.466 24.9066 24.02 25 23.5694 25H16.4212C17.1276 23.8394 17.502 22.4895 17.5 21.1111C17.5 18.8778 16.5363 16.88 15.0237 15.5556H18.2917C18.5016 15.5556 18.703 15.4678 18.8515 15.3115C18.9999 15.1552 19.0833 14.9432 19.0833 14.7222C19.0833 14.5012 18.9999 14.2892 18.8515 14.133C18.703 13.9767 18.5016 13.8889 18.2917 13.8889H12.4861C12.3172 13.8889 12.1621 13.9444 12.0333 14.0378C10.6807 13.7442 9.27558 13.8851 8 14.4422V7.5ZM22.7778 23.3333H23.5694C24.0594 23.3333 24.5292 23.1285 24.8756 22.7638C25.222 22.3992 25.4167 21.9046 25.4167 21.3889V18.8889H22.7778V23.3333ZM11.6944 10.2778C11.6944 10.7378 12.0491 11.1111 12.4861 11.1111H18.2917C18.5016 11.1111 18.703 11.0233 18.8515 10.867C18.9999 10.7108 19.0833 10.4988 19.0833 10.2778C19.0833 10.0568 18.9999 9.8448 18.8515 9.68852C18.703 9.53224 18.5016 9.44444 18.2917 9.44444H12.4861C12.2761 9.44444 12.0748 9.53224 11.9263 9.68852C11.7779 9.8448 11.6944 10.0568 11.6944 10.2778Z"
      fill="#B5B8BF"/>
  </svg>
);

export default ReceiptDeclinedIcon;
