import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledInvoiceTypeRadioGroup, StyledInvoiceTypeRadioGroupLabel} from './StyledInvoiceTypeRadioGroup';
import RadioGroupIcon from '../../../RadioGroupIcon';
import {transactionsHelpers} from '../../../../utils/helpers';

const InvoiceTypeRadioGroup = ({
  defaultValue,
  enabledEmpty,
  onChange,
  value,
  ...rest
}) => {
  const [elementValue, setElementValue] = useState(defaultValue);
  const [t] = useTranslation('main');

  const options = useMemo(() => transactionsHelpers.getInvoiceTypeOptions({enabledEmpty, t}), [enabledEmpty, t]);

  useEffect(() => setElementValue(value), [value]);

  const handleOnChange = (e) => {
    let {value} = e.target;
    setElementValue(value);
    onChange && onChange(e, value);
  }

  return (
    <StyledInvoiceTypeRadioGroup {...rest}>
      <StyledInvoiceTypeRadioGroupLabel>
        {t('document')} {t('type')}
      </StyledInvoiceTypeRadioGroupLabel>
      <RadioGroupIcon
        onChange={handleOnChange}
        options={options}
        value={elementValue}
      />
    </StyledInvoiceTypeRadioGroup>
  );
}

InvoiceTypeRadioGroup.propTypes = {
  enabledEmpty: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
}

InvoiceTypeRadioGroup.defaultProps = {
  enabledEmpty: true,
  totalLoading: false
}


export default InvoiceTypeRadioGroup;
