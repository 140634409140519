import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Space} from 'antd';
import {EditFilled} from '@ant-design/icons';
import {
  StyledInvoiceDetailsCard,
  StyledInvoiceDetailsFilePreview,
  StyledInvoiceDetailsAttachmentPreview,
  StyledInvoiceDetailsDownloadButton
} from './StyledInvoiceDetails';
import InvoiceAttachmentPreview from '../InvoiceAttachmentPreview';
import ImagePreviewTooltip from '../ImagePreviewTooltip';
import TransactionExpenseTypeForm from '../TransactionExpenseTypeForm/TransactionExpenseTypeForm';
import {DownloadIcon, ReceiptDeclinedIcon} from '../../../../icons';


const InvoiceDetails = ({
  attachment,
  isDisableEdit,
  isEnableDownloadButton,
  transaction,
  onClose,
  onDownload,
  onEdit,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation('main');

  const isExistAttachment = attachment && attachment.file;

  const handleOnEdit = () => onEdit && onEdit(transaction);

  const handleDownload = (e) => onDownload && onDownload(e);

  const extra = (
    <Space align='center' size='middle'>
      {(isExistAttachment && isEnableDownloadButton) && (
        <StyledInvoiceDetailsDownloadButton
          onClick={handleDownload}
          type='text'
        >
          <DownloadIcon /> {t('download')}
        </StyledInvoiceDetailsDownloadButton>
      )}
      {(!isDisableEdit && !isExistAttachment) && (
        <Button
          icon={<EditFilled />}
          onClick={handleOnEdit}
          size='small'
          type='text'
        />
      )}
    </Space>
  );

  const invoiceAttachment = (
    <InvoiceAttachmentPreview
      attachment={attachment}
    />
  );

  return (
    <>
      <StyledInvoiceDetailsCard
        title={t('invoice')}
        type='inner'
        extra={extra}
        {...rest}
      >
        {isExistAttachment ? (
          <Space direction='vertical' size='middle'>
            <TransactionExpenseTypeForm
              transaction={transaction}
              onSubmit={onSubmit}
            />
            <ImagePreviewTooltip
              overlayClassName='image-preview-tooltip-overlay single'
              trigger='hover'
              title={invoiceAttachment}
            >
              <StyledInvoiceDetailsFilePreview>
                <StyledInvoiceDetailsAttachmentPreview
                  attachment={attachment}
                  onClick={(e) => !isEnableDownloadButton && handleDownload(e)}
                />
              </StyledInvoiceDetailsFilePreview>
            </ImagePreviewTooltip>
          </Space>
        ) : isDisableEdit ? null : (transaction && transaction.expense) && (
          <Space direction='vertical' size={0}>
            <ReceiptDeclinedIcon />
            <span>{t(`invoiceTypes.noReceipt`)}</span>
          </Space>
        )}
      </StyledInvoiceDetailsCard>
    </>
  );
}

InvoiceDetails.propTypes = {
  attachment: PropTypes.shape({
    file: PropTypes.string,
    name: PropTypes.string
  }),
  isDisableEdit: PropTypes.bool,
  isEnableDownloadButton: PropTypes.bool,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  onEdit: PropTypes.func,
  onSubmit: PropTypes.func,
  transaction: PropTypes.object
}

InvoiceDetails.defaultProps = {
  isDisableEdit: false,
  isEnableDownloadButton: false
}

export default InvoiceDetails;
