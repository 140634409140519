import styled from 'styled-components';
import {bordersStyles} from '../../../../utils/styles';

const StyledUploadInvoicesModal = styled.div`
  overflow-y: auto;
  
  .ant-upload-wrapper + .ant-space,
  .invoice-type-radio-group {
    margin-top: 16px;
  }
  
  .ant-upload-select {
    background-color: ${props => props.theme.colors.neutral_2};
  }
`;

const StyledFilePreview = styled.div`
  ${bordersStyles.default};
  
  color: ${props => props.theme.colors.neutral_10};
  font-size: 14px;
  padding: 16px;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .attachment-preview {
    ${bordersStyles.default};
    
    overflow: hidden;
    text-align: center;
    height: 72px;
    max-height: unset;
    width: 72px;
  }
  
  .ant-btn {
    border: none;
  }
  
  .file-preview-details {
    
  }
`;

export {
  StyledUploadInvoicesModal,
  StyledFilePreview
};
