const expenseStatusesConstants = Object.freeze({
  TO_APPROVE: 0,
  APPROVED: 1,
  AWAITING_PAYMENT: 2,
  PAID: 3,
  DRAFT: 4,
  REJECTED: 5,
  TRANSACTION_AUTHORIZED: 6,
  TRANSACTION_REJECTED: 7,
  TRANSACTION_REFUND: 8,
  TRANSACTION_REVERSED: 9
});

const expenseTypesConstants = Object.freeze({
  INVOICE: 0,
  RECEIPT: 1,
  NO_RECEIPT: 2
});

const expenseInvoicesStatusConstants = Object.freeze({
  CONFIRMED: 'CONFIRMED',
  MATCHED: 'MATCHED',
  PENDING: 'PENDING',
});

export {
  expenseStatusesConstants,
  expenseTypesConstants,
  expenseInvoicesStatusConstants
};

