import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Form, Select} from 'antd';
import SpinSmall from '../../../../SpinSmall';
import {formHelpers, objectHelpers, transactionsHelpers} from '../../../../../utils/helpers';

const InvoiceTypeForm = ({
  dispatch,

  edit,
  onSubmit,
  invoice,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    type: undefined
  });

  const invoiceTypeOptions = useMemo(() => transactionsHelpers.getInvoiceTypeOptions({t}), [t]);

  useEffect(() => {
    const gObjProp = (key, defaultValue) => objectHelpers.getObjProp(invoice, key, defaultValue);

    const fieldValues = {
      ...initialFormValues,
      type: gObjProp('type'),
    };
    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
  }, [invoice]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleOnSave = () => form.submit();

  const handleSubmit = (fields) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(
        fields,
        () => setLoading(false),
        (error) => {
          setLoading(false);
          let fields = formHelpers.getFormServerErrorFields(error.response.data);
          form.setFields(fields);
        }
      )
    }
  }

  return (
    <SpinSmall spinning={loading}>
      <Form
        initialValues={initialFormValues}
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        requiredMark={false}
        {...rest}
      >
        <Form.Item
          label={`${t('document')} ${t('type')}`}
          name='type'
        >
          <Select
            onChange={handleOnSave}
            options={invoiceTypeOptions}
            size='large'
          />
        </Form.Item>
      </Form>
    </SpinSmall>
  );
}

InvoiceTypeForm.propTypes = {
  onSubmit: PropTypes.func,
  invoice: PropTypes.object
}

export default InvoiceTypeForm;
