import styled from 'styled-components';

const StyledTransactionDetails = styled.div`
  display: flex;
  flex-direction: column;
  
  .ant-card-head {
    background-color: ${props => props.theme.colors.neutral_2};
  }
  
  form {
    text-align: left;
  }
  
`;

export {
  StyledTransactionDetails
};
