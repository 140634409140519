const invoiceStatusConstants = Object.freeze({
  MATCHED: 'MATCHED',
  PARSED: 'PARSED',
  PENDING: 'PENDING'
});

const invoiceTypeConstants = Object.freeze({
  INVOICE: 'INVOICE',
  NO_RECEIPT: 'NO_RECEIPT',
  RECEIPT: 'RECEIPT'
});

const invoiceMatchTypeConstants = Object.freeze({
  AUTOMATED: 'AUTOMATED',
  MANUAL: 'MANUAL',
});

export {
  invoiceStatusConstants,
  invoiceTypeConstants,
  invoiceMatchTypeConstants,
};
