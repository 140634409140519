import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AddInvoiceModal from '../../../../components/pages/TransactionsPage/AddInvoiceModal/AddInvoiceModal';
import {
  expenseActions,
  invoicesActions,
  transactionActions
} from '../../../../state/actions';
import {
  objectHelpers,
  transactionsHelpers
} from '../../../../utils/helpers';
import {expenseInvoicesStatusConstants, expenseTypesConstants, invoiceTypeConstants} from '../../../../constants';


const InvoiceManager = ({
  addInvoiceModalProps,
  batchCreateInvoices,
  closeAddInvoiceModal,
  linkExpense,
  unlinkExpense,
  updateExpenseType,

  isOpenDetails,
  modalProps,
  onExpenseUpdate,
  selectedTransaction,
  successUpdateHandler,
}) => {

  const addInvoiceModalMode = useMemo(() => {
    return transactionsHelpers.getInvoiceModalMode({
      addInvoiceModalProps,
      isOpenDetails,
      selectedTransaction
    });
  }, [addInvoiceModalProps, isOpenDetails, selectedTransaction]);

  const onInvoiceRemove = (successCallback, errorCallback) => {
    if (selectedTransaction.expense) {
      const {expense} = selectedTransaction;
      const expenseId = expense.id;
      unlinkExpense(
        {expense_id: expenseId},
        () => {
          onExpenseUpdate({
            ...expense,
            expense_invoice_status: undefined,
            is_attachment_uploaded: false
          }, 'attachment');
          successCallback && successCallback();
          successUpdateHandler && successUpdateHandler();
          if (updateExpenseType) {
            updateExpenseType(
              expenseId,
              {expense_type: expenseTypesConstants.NO_RECEIPT}
            );
          }
        },
        (resp) => errorCallback && errorCallback(resp)
      );
    } else {
      errorCallback && errorCallback();
    }
  }

  const handleOkAttachmentModal = (formData, successCallback, errorCallback) => {
    const transaction = objectHelpers.getObjProp(addInvoiceModalProps, 'transaction');

    if (transaction.expense) {
      const {invoice, file} = formData;
      const {expense} = transaction;
      const handleLinkExpense = (invoiceId) => {
        linkExpense(
          invoiceId,
          {expense_id: transaction.expense.id},
          () => {
            onExpenseUpdate({
              ...expense,
              is_attachment_uploaded: true,
              expense_invoice_status: expenseInvoicesStatusConstants.MATCHED
            }, 'attachment');
            closeAddInvoiceModal();
            successCallback && successCallback(file);
            successUpdateHandler && successUpdateHandler();
          },
          (resp) => errorCallback && errorCallback(resp)
        )
      }

      if (formData.expense_type === invoiceTypeConstants.NO_RECEIPT && onInvoiceRemove) {
        onInvoiceRemove(
          () => {
            successCallback && successCallback();
            closeAddInvoiceModal();
          },
          (resp) => errorCallback && errorCallback(resp)
        );
      } else if (file) {
        batchCreateInvoices(
          {invoices: [{base64_file: file.file, file_name: file.name}]},
          (invoices) => {
            if (invoices.length > 0) {
              handleLinkExpense(invoices[0].id);
            } else {
              successCallback && successCallback();
            }
          },
          (resp) => errorCallback && errorCallback(resp)
        )
      } else {
        handleLinkExpense(invoice);
      }
    }
  }

  return (
    <>
      <AddInvoiceModal
        mode={addInvoiceModalMode}
        onCancel={closeAddInvoiceModal}
        open={addInvoiceModalProps.open}
        transaction={addInvoiceModalProps.transaction}
        onOk={handleOkAttachmentModal}
        {...modalProps}
      />
    </>
  );
}

InvoiceManager.propTypes = {
  isOpenDetails: PropTypes.bool,
  modalProps: PropTypes.object,
  onExpenseUpdate: PropTypes.func,
  selectedTransaction: PropTypes.object,
  successUpdateHandler: PropTypes.func,
}

InvoiceManager.defaultProps = {
  isOpenDetails: false,
}

const mapStateToProps = state => {
  const {addInvoiceModalProps} = state.transaction;

  return {
    addInvoiceModalProps,
  }
}

const mapDispatchToProps = {
  batchCreateInvoices: invoicesActions.batchCreateInvoices,
  closeAddInvoiceModal: transactionActions.closeAddInvoiceModal,
  linkExpense: invoicesActions.linkExpense,
  unlinkExpense: invoicesActions.unlinkExpense,
  updateExpenseType: expenseActions.updateExpenseType,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceManager);
