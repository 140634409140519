import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Form, Select} from 'antd';
import SpinSmall from '../../../SpinSmall';
import {formHelpers, objectHelpers, transactionsHelpers} from '../../../../utils/helpers';

const TransactionExpenseTypeForm = ({
  dispatch,

  edit,
  onSubmit,
  transaction,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    expense_type: undefined
  });

  const expenseTypeOptions = useMemo(() => transactionsHelpers.getExpenseTypeOptions({t}), [t]);

  useEffect(() => {
    const gExpenseProp = (key, defaultValue) => objectHelpers.getObjProp(transaction?.expense, key, defaultValue);

    const fieldValues = {
      ...initialFormValues,
      expense_type: gExpenseProp('expense_type'),
    };
    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
  }, [transaction]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleSubmit = (fields) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(
        fields,
        () => setLoading(false),
        (error) => {
          setLoading(false);
          let fields = formHelpers.getFormServerErrorFields(error.response.data);
          form.setFields(fields);
        }
      )
    }
  }

  const handleOnSave = () => form.submit();

  return (
    <SpinSmall spinning={loading}>
      <Form
        initialValues={initialFormValues}
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        requiredMark={false}
        {...rest}
      >
        <Form.Item
          label={`${t('document')} ${t('type')}`}
          name='expense_type'
        >
          <Select
            onChange={handleOnSave}
            options={expenseTypeOptions}
            size='large'
          />
        </Form.Item>
      </Form>
    </SpinSmall>
  );
}

TransactionExpenseTypeForm.propTypes = {
  onSubmit: PropTypes.func,
  transaction: PropTypes.object
}

export default TransactionExpenseTypeForm;
