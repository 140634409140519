import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import {useNavigate} from 'react-router-dom';
import VendorDetails from '../../VendorDetails';
import SubscriptionTab from '../SubscriptionTab';
import CostCenter from '../../CostCenter';
import SubscriptionUsers from '../../tabComponents/SubscriptionUsers/SubscriptionUsers';
import SubscriptionPaymentType from '../../SubscriptionPaymentType';
import {amountHelpers, cardsHelpers, dateHelpers, subscriptionsHelpers} from '../../../../../utils/helpers';
import {subscriptionViewTypesConstants} from '../../../../../constants';

const {AUTHORIZED} = subscriptionViewTypesConstants;

const tableClassName = 'authorized-table';
const defaultSelectedColumns = ['payment', 'owner', 'users_count', 'logins_count', 'next_payment',
  'total_payment', 'budget', 'contract_renewal_date', 'status', 'tags'];

const AuthorizedTab = ({
  filterProps,
  handleAction,
  tableProps,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const navigate = useNavigate();
  const [selectedColumns, setSelectedColumns] = useState(subscriptionsHelpers.getDefaultTableColumns(AUTHORIZED, defaultSelectedColumns));

  const trans = (key) => t(`subscriptions:${key}`);

  const columns = [
    {
      dataIndex: 'vendor',
      title: t('vendor'),
      render: (_, record) => <VendorDetails data={record} />,
      sorter: true
    },
    {
      dataIndex: 'payment',
      title: t('payment'),
      render: (_, record) => !record.placeholder && <SubscriptionPaymentType data={record} />
    },
    {
      dataIndex: 'card_masked_pan',
      title: trans('last4Digits'),
      render: cardsHelpers.getLast4Digits
    },
    {
      align: 'center',
      dataIndex: 'owner',
      title: t('Owner'),
      render: (_, record) => subscriptionsHelpers.getSubscriptionOwner(record),
      sorter: true,
      editable: true
    },
    {
      align: 'right',
      dataIndex: 'users_count',
      title: t('users'),
      render: (_, record) => <SubscriptionUsers subscription={record} />,
      sorter: true
    },
    {
      align: 'center',
      dataIndex: 'logins_count',
      title: trans('usage'),
      render: (value) => subscriptionsHelpers.getLoginsCount(value),
      sorter: true
    },
    {
      align: 'right',
      dataIndex: 'next_payment',
      title: `${t('next')} ${t('payment')}`,
      render: (_, record) => !record.placeholder && (
        <span className='date'>
          {subscriptionsHelpers.getNextPaymentDate(record)}
        </span>
      ),
      sorter: true
    },
    {
      align: 'right',
      dataIndex: 'total_payment',
      title: `${t('total')} ${t('payment')}`,
      render: (_, record) => {
        if (!record.placeholder) {
          return (
            <Space className='amount'>
              <span>{amountHelpers.getAmountWithCurrencyCode(record.total_payment)}</span>
            </Space>
          )
        }
      },
      sorter: true
    },
    {
      align: 'right',
      dataIndex: 'budget',
      title: t('Budget'),
      render: (_, data) => subscriptionsHelpers.getBudgetBilledValue({t, data})
    },
    {
      align: 'right',
      dataIndex: 'contract_renewal_date',
      title: t('renewalDate'),
      render: (value, record) => {
        if (record.placeholder) return ;
        if (value) {
          value = dateHelpers.getTimestampDateObject(value);
          return (
            <span className='date'>
              {dateHelpers.getDateWithGMTFormat(value, 'DD MMM YYYY')}
            </span>
          );
        } else {
          return '-';
        }
      }
    },
    {
      dataIndex: 'status',
      title: t('status'),
      render: (_, record) => !record.placeholder && subscriptionsHelpers.getSubscriptionStatusMark(record),
      sorter: true,
      editable: true
    },
    {
      dataIndex: 'tags',
      width: 190,
      title: trans('costCentre'),
      render: (tags) => <CostCenter tags={tags} />,
      editable: true
    },
  ];

  const availableColumns = useMemo(() => subscriptionsHelpers.getAvailableColumns(columns, selectedColumns), [selectedColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnRowClick = (e, record) => {
    if (e.target.closest(`.${tableClassName}`)) navigate(subscriptionsHelpers.getSubscriptionDetailsPageUrl(record));
  }

  const onChangeSelectedItems = (value) => {
    setSelectedColumns(value);
    subscriptionsHelpers.storeTabColumns(AUTHORIZED, value);
  }

  const getRightSideContent = () => subscriptionsHelpers.getSettingsRightSideContent({
    columns,
    filterProps,
    onChange: onChangeSelectedItems,
    selectedKeys: selectedColumns
  });

  return (
    <SubscriptionTab
      {...rest}
      columns={availableColumns}
      tableProps={{
        editable: true,
        ...tableProps,
        className: tableClassName,
        onRow: (record) => ({
          onClick: (e) => handleOnRowClick(e, record)
        })
      }}
      filterProps={{
        ...filterProps,
        rightSideContent: getRightSideContent()
      }}
    />
  );
}

AuthorizedTab.propTypes = {
  data: PropTypes.array,
  filterProps: PropTypes.object,
  handleAction: PropTypes.func,
  tableProps: PropTypes.object
}

AuthorizedTab.defaultProps = {
  data: []
}

export default AuthorizedTab;
